import React, { useCallback, useEffect, useState } from 'react'
import cx from 'classnames'
import { useDevice } from 'device'
import { usePathname } from 'router'
import { useEventListener } from 'hooks'

import { useAccess } from 'models/user'
import { useNavigation, useSingleAccessLink } from 'models/admin'

import { Icon, Text } from 'components/dataDisplay'
import { ButtonBase } from 'components/inputs'
import { Portal } from 'components/layout'

import Menu from 'compositions/admin/Menu/Menu'
import logoRir from 'compositions/Footer/components/LogoBottom/images/rir.svg'
import Chat from 'compositions/admin/mapLayers/AdminHeader/Chat/Chat'
import Notifications from 'compositions/admin/mapLayers/AdminHeader/Notifications/Notifications'
import ProfileButton from 'compositions/admin/mapLayers/AdminHeader/components/ProfileButton/ProfileButton'


type AdminLayersNavigationProps = {
  className?: string
}

const AdminLayersNavigation: React.FC<AdminLayersNavigationProps> = (props) => {
  const { className } = props
  const { tag } = useNavigation()
  const pathname = usePathname()
  const { isDesktop } = useDevice()
  const [ offset, setOffset ] = useState(0)
  const { role, isFetching: isRoleFetching } = useAccess('CHAT')
  const [ isOpen, setOpen ] = useState(false)
  const singleAccessLink = useSingleAccessLink()

  useEffect(() => {
    if (singleAccessLink) {
      document.body?.classList.add('w-full')

      return () => {
        document.body?.classList.remove('w-full')
      }
    }
  }, [ singleAccessLink ])

  useEffect(() => {
    setOpen(isDesktop)
  }, [ isDesktop ])

  useEffect(() => {
    if (!isDesktop) {
      return () => {
        setOpen(false)
      }
    }
  }, [ pathname, isDesktop ])

  const withChat = role && role !== 'ANONYMOUS'

  const id = 'adminHeaderButton'
  const logoHeight = 96

  const scrollHandler = useCallback(() => {
    const scrollTop = window.scrollY
    const innerHeight = window.innerHeight
    const container = document.getElementById(id)

    if (container) {
      const bottomOffset = innerHeight - (container.offsetHeight - scrollTop) + 80
      const offset = bottomOffset < logoHeight ? logoHeight - bottomOffset : 0

      setOffset(offset)
    }
  }, [ setOffset ])

  useEventListener('scroll', scrollHandler)

  useEffect(() => {
    scrollHandler()
  }, [ scrollHandler ])

  return (
    <>
      <Portal id="dropdowns">
        <div
          id="adminHeaderButton"
          className="flex items-center absolute"
          style={{
            top: '32px',
            right: '40px',
            // zIndex: 802, // TODO move z-index to variables
          }}
        >
          {
            withChat && (
              <Chat
                className="mr-32px block"
              />
            )
          }
          <Notifications
            className="mr-32px block"
          />
          <ProfileButton
            className="z-802"
            tag={tag}
            withUserName={isDesktop}
          />
        </div>
      </Portal>
      {
        !singleAccessLink && (
          <nav
            className={cx('flex flex-col h-full justify-between', className, {
              'fixed h-full z-800 bg-gladiator': !isDesktop && isOpen,
              'relative': !isDesktop && !isOpen,
            })}
            style={{
              width: !isDesktop && isOpen ? '25.3%' : undefined,
              minWidth: !isDesktop && isOpen ? '350px' : undefined,
            }}
          >
            <div
              id={id}
              className="overflow-hidden"
            >
              {
                !isDesktop && (
                  <ButtonBase
                    className="admin-layout-button bg-white radius-100 absolute"
                    onClick={() => setOpen(!isOpen)}
                  >
                    <Icon
                      name={isOpen ? 'arrow/arrow-left_12' : 'arrow/arrow-right_12'}
                      color="metropolis"
                    />
                  </ButtonBase>
                )
              }
              <Text
                className={cx('min-w-180 transition-opacity mt-40px ml-40px', {
                  'opacity-0': !isOpen,
                  'opacity-100': isOpen,
                })}
                message="Личный кабинет администратора"
                size="h20-24"
                color="amelie"
              />
              <Menu
                className="mt-32px mb-96px"
                textClassName={cx({
                  'pl-24px': !isDesktop && !isOpen,
                  'pl-36px': isDesktop || isOpen,
                })}
                isClosed={!isDesktop && !isOpen}
                onMenuToggle={(isMenuOpen) => {
                  scrollHandler()

                  if (!isDesktop && !isOpen && isMenuOpen) {
                    setOpen(true)
                  }
                }}
              />
            </div>
            <div
              className={cx('fixed transition-opacity', {
                'opacity-0': !isOpen,
                'opacity-100': isOpen,
              })}
              style={{
                bottom: `${40 - offset}px`,
                left: '40px',
                width: '143px',
                height: '40px',
              }}
            >
              <img src={logoRir} alt="Logo" />
            </div>
          </nav>
        )
      }
    </>
  )
}

export default AdminLayersNavigation