import React, { useEffect, useState } from 'react'
import links from 'links'
import { usePathname } from 'router'

import { Href } from 'components/navigation'

import OpenButton from 'compositions/admin/mapLayers/AdminHeader/DropdownAction/components/OpenButton/OpenButton'
import ChatButton from 'compositions/admin/mapLayers/AdminHeader/Chat/components/ChatButton'


type ChatProps = {
  className?: string
}

const Chat: React.FC<ChatProps> = (props) => {
  const { className } = props

  const [ count, setCount ] = useState(0)
  const pathname = usePathname()

  const isChatPage = pathname !== links.admin.chat

  useEffect(() => {
    window.setChatCount = setCount
  }, [])

  return (
    <Href
      to={isChatPage ? `${links.admin.chat}?from=${pathname}` : null}
    >
      {
        isChatPage ? (
          <ChatButton
            className={className}
            initialCount={count}
            setInitialCount={setCount}
          />
        ) : (
          <OpenButton
            className={className}
            icon="communication/message_20"
            count={count}
          />
        )
      }
    </Href>
  )
}


export default React.memo(Chat)
